<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />
    <el-backtop target=".el-main"></el-backtop>

    <div class="search">
      <div style="display: flex">
        <el-select v-model="searchType" placeholder="请选择查询类别">
          <el-option label="趣宝盒ID" value="hardwareId"></el-option>
          <el-option label="手机号" value="phone"></el-option>
          <el-option label="激活批次" value="activationBatch"></el-option>
          <el-option label="过期时间" value="untilTime"></el-option>
        </el-select>
        <el-input
          v-model="hardwareId"
          v-if="searchType == 'hardwareId'"
          placeholder="请输入趣宝盒ID"
          @change="handleChangeSearch(1)"
        ></el-input>
        <div v-else-if="searchType == 'phone'">
          <el-select
            v-model="valueArea"
            filterable
            clearable
            placeholder="请选择区号(可搜索关键字)"
            style="width: 260px"
            v-if="internationType"
          >
            <el-option
              v-for="item in options"
              :key="item.name"
              :label="item.name + item.tel"
              :value="item.tel"
            >
            </el-option>
          </el-select>
          <el-input
            v-model="phone"
            placeholder="请输入手机号"
            @change="handleChangeSearch(2)"
          ></el-input>
        </div>
        <el-input
          v-model="activationBatch"
          v-else-if="searchType == 'activationBatch'"
          placeholder="请输入激活批次"
          @change="handleChangeSearch(3)"
        ></el-input>
        <el-date-picker
          v-model="untilTime"
          v-else-if="searchType == 'untilTime'"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          @change="handleChangeSearch(4)"
        >
        </el-date-picker>

        <el-button type="primary" @click="searchList">查询</el-button>
        <el-button type="info" @click="reset">重置</el-button>
      </div>

      <div>
        <el-button @click="exportToExcel">导出模版</el-button>
        <el-button @click="selectFile">导入文件</el-button>
      </div>

      <el-dialog title="选择文件" :visible.sync="dialogTableVisible">
        <el-form :model="form">
          <el-form-item label="激活批次" :label-width="formLabelWidth">
            <el-input
              v-model="form.batch"
              placeholder="请输入激活批次"
            ></el-input>
          </el-form-item>
          <el-form-item label="excel文件上传" label-width="120px">
            <el-upload
              action=""
              :auto-upload="false"
              :on-change="(file, fileList) => elInFile(file, fileList)"
              ref="upload"
              class="upload-demo"
              accept=".xlsx, .xls"
            >
              <el-button slot="trigger" size="mini" type="success" plain
                >选取文件</el-button
              >
            </el-upload>
            <el-button @click="handlePreview" style="margin-left: 10px">
              预览
            </el-button>
            <el-dialog
              title="预览文件"
              :visible.sync="dialogVisible"
              :append-to-body="true"
              width="70%"
            >
              <el-table
                :data="tableDataSee"
                border
                style="width: 100%"
                height="100%"
              >
                <el-table-column
                  type="index"
                  label="序号"
                  width="80"
                  align="center"
                >
                </el-table-column>

                <el-table-column prop="ICCID" label="ICCID" width="200">
                </el-table-column>
                <el-table-column
                  prop="二维码"
                  label="二维码/盒子ID"
                  width="200"
                >
                </el-table-column>
                <el-table-column
                  prop="ICCID后四位"
                  label="ICCID后四位"
                  width="160"
                >
                </el-table-column>
                <el-table-column prop="加密后8位" label="加密后8位" width="200">
                </el-table-column>
                <el-table-column prop="ID" label="ID" width="200">
                </el-table-column>
              </el-table>
            </el-dialog>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogTableVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleFormSubmit">确 定</el-button>
        </div>
      </el-dialog>
    </div>

    <div class="table_con">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="序号" width="80" align="center">
        </el-table-column>
        <el-table-column
          prop="boxEntryEntity.batchId"
          label="激活批次"
          width="180"
        >
        </el-table-column>
        <el-table-column prop="hardwareEntity.iccid" label="ICCID" width="200">
        </el-table-column>
        <el-table-column
          prop="hardwareEntity.hardwareId"
          label="二维码/盒子ID"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="boxEntryEntity.splicingId"
          label="ICCID后四位&盒子id后八位"
          width="200"
        >
        </el-table-column>
        <el-table-column prop="hardwareEntity.uid" label="激活状态" width="180">
          <template slot-scope="scope">
            {{ scope.row.hardwareEntity.uid == 0 ? "未激活" : "已激活" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="hardwareEntity.firstRequestTime"
          label="生效时间"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="hardwareEntity.untilTime"
          label="过期时间"
          width="200"
        >
        </el-table-column>
        <el-table-column prop="phoneArea" label="区号"> </el-table-column>
        <el-table-column prop="phone" label="绑定手机" width="180">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small"
              >编辑</el-button
            >
            <el-dialog
              title="编辑过期时间"
              :visible.sync="dialogEditVisible"
              :append-to-body="true"
              width="30%"
            >
              <el-date-picker
                v-model="editDate"
                type="date"
                placeholder="选择过期日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogEditVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleClickSubmit"
                  >确 定</el-button
                >
              </div>
            </el-dialog>
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import * as XLSX from "xlsx";

import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";

import { showLoading, hideLoading } from "@/util/loading";
import { format_time_date } from "@/util/funBusiness";
import { areaCodeData } from "@/util/areaCodeData";

export default {
  components: {
    Breadcrumb,
    Pagination,
  },

  inject: ["reload"],

  data() {
    return {
      BreadcrumbCon: [
        {
          name: "行程上链",
        },
        {
          name: "趣宝盒数据",
        },
        {
          name: "趣宝盒ID库",
        },
      ], // 面包屑数据

      searchType: "",
      hardwareId: "",
      option: [],
      valueArea: "",
      phone: "",
      activationBatch: "",
      untilTime: "",

      internationType: false,
      role: "",
      phoneAreaROLE: "",

      dialogTableVisible: false,
      dialogEditVisible: false,
      editDate: "",
      rowDataId: "",
      formLabelWidth: "120px",
      form: {
        batch: "",
        file: "",
      },

      tableData: [],

      dialogVisible: false,
      fileTemp: null,
      outdata: [],
      tableDataSee: [],

      file: [],

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100],
    };
  },

  mounted() {
    this.options = areaCodeData;
    this.role = localStorage.getItem("ROLE");

    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");

    showLoading();
    const opt = {
      url: reqUrl.getBoxEntryLogList,
      params: JSON.stringify({
        hardwareId: "",
        phone: "",
        phoneArea: "",
        batchNo: "",
        untilTime: "",
      }),
      method: "POST",
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code == 0) {
          if (res.data.data.list != null) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;

            res.data.data.list.map((item) => {
              item.hardwareEntity.firstRequestTime = format_time_date(
                item.hardwareEntity.firstRequestTime
              );
              item.hardwareEntity.untilTime = format_time_date(
                item.hardwareEntity.untilTime
              );
            });
          } else {
            this.tableData = [];
          }
          hideLoading();
        }
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(opt);
  },

  methods: {
    searchList() {
      showLoading();
      const opt = {
        url: reqUrl.getBoxEntryLogList,
        params: JSON.stringify({
          hardwareId: this.hardwareId,
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          batchNo: Number(this.activationBatch),
          untilTime: this.untilTime,
        }),
        method: "POST",
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code == 0) {
            if (res.data.data) {
              this.tableData = res.data.data.list;
              this.pageLength = res.data.data.total;
              this.pageSize = res.data.data.pageSize;

              res.data.data.list.map((item) => {
                item.hardwareEntity.firstRequestTime = format_time_date(
                  item.hardwareEntity.firstRequestTime
                );
                item.hardwareEntity.untilTime = format_time_date(
                  item.hardwareEntity.untilTime
                );
              });
            } else {
              this.tableData = [];
            }
            hideLoading();
          }
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    UploadBatch() {
      return reqUrl.insertBoxBatch;
    },

    selectFile() {
      this.dialogTableVisible = true;

      const opt = {
        url: reqUrl.getNextBatchId,
        method: "POST",
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code == 0) {
            this.form.batch = res.data.data;
          }
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    exportToExcel() {
      let excelData = [
        ["ICCID", "二维码", "ICCID后四位", "加密后8位", "ID"], //这里是表头数据
      ];
      let ws = XLSX.utils.aoa_to_sheet(excelData);
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "工作簿名称");
      XLSX.writeFile(wb, "文件名（可修改）.xlsx");
    },

    handlePreview() {
      this.dialogVisible = true;
      this.tableDataSee = this.outdata;
    },

    elInFile(file, fileList) {
      this.fileTemp = file.raw;
    },

    importExcelData(obj) {
      let _this = this;
      // 通过DOM取文件数据
      this.file = obj;
      let rABS = false; //是否将文件读取为二进制字符串
      let file = this.file;
      let reader = new FileReader();
      FileReader.prototype.readAsBinaryString = function (f) {
        let binary = "";
        let rABS = false; //是否将文件读取为二进制字符串
        let wb; //读取完成的数据
        let outdata;
        let reader = new FileReader();
        reader.onload = function (e) {
          let bytes = new Uint8Array(reader.result);
          let length = bytes.byteLength;
          for (let i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          let XLSX = require("xlsx");
          if (rABS) {
            wb = XLSX.read(btoa(fixdata(binary)), {
              //手动转化
              type: "base64",
            });
          } else {
            wb = XLSX.read(binary, {
              type: "binary",
            });
          }
          outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]); //outdata就是你想要的东西
          _this.outdata = [...outdata];
        };
        reader.readAsArrayBuffer(f);
      };
      if (rABS) {
        reader.readAsArrayBuffer(file);
      } else {
        reader.readAsBinaryString(file);
      }
    },

    // 导入表格
    handleFormSubmit() {
      if (this.form.batch && this.fileTemp !== null) {
        let formData = new FormData(); //声明一个FormDate对象
        formData.append("file", this.fileTemp); //把文件信息放入对象中
        formData.append("batchNo", this.form.batch); //把文件信息放入对象中

        axios.defaults.headers.post["Authorization"] = localStorage.getItem(
          "resHeadersAuthorization"
        );
        axios({
          method: "POST",
          url: reqUrl.insertBoxBatch,
          header: { "Content-Type": "multipart/form-data" },
          data: formData,
        })
          .then((res) => {
            console.log(res);
            if (res.data.code == 0) {
              this.$message.success("导入成功");

              this.dialogTableVisible = false;
              this.form.batch = "";
              this.form.type = true;
              this.form.file = "";
              this.refresh();
            } else {
              this.$message.error(res.data.message);
            }
          })
          .catch((err) => {
            this.$message({
              type: "error",
              message: "导入失败",
              err,
            });
          });
      } else {
        this.$message({
          type: "error",
          message: "批次号不可为空或excel文件未上传",
        });
      }
    },

    handleChangeSearch(index) {
      if (index == 1) {
        this.phone = "";
        this.phoneArea = "";
        this.activationBatch = "";
        this.untilTime = "";
      } else if (index == 2) {
        this.hardwareId = "";
        this.activationBatch = "";
        this.untilTime = "";
      } else if (index == 3) {
        this.hardwareId = "";
        this.phone = "";
        this.phoneArea = "";
        this.untilTime = "";
      } else if (index == 4) {
        this.hardwareId = "";
        this.phone = "";
        this.phoneArea = "";
        this.activationBatch = "";
      }
    },

    handleClick(row) {
      this.dialogEditVisible = true;
      this.rowDataId = row.hardwareEntity.hardwareId;
    },

    handleClickSubmit() {
      showLoading();
      const opt = {
        url: reqUrl.editBoxEntryLog,
        params: JSON.stringify({
          hardwareId: this.rowDataId,
          untilTime: this.editDate,
        }),
        method: "POST",
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.dialogEditVisible = false;
            showLoading();
            const opt = {
              url: reqUrl.getBoxEntryLogList,
              params: JSON.stringify({
                hardwareId: this.hardwareId,
                phone: this.phone,
                phoneArea: this.valueArea || this.phoneAreaROLE,
                batchNo: Number(this.activationBatch),
                untilTime: this.untilTime,
              }),
              method: "POST",
              resFunc: (res) => {
                console.log(res.data);
                if (res.data.code == 0) {
                  if (res.data.data) {
                    this.tableData = res.data.data.list;
                    this.pageLength = res.data.data.total;
                    this.pageSize = res.data.data.pageSize;

                    res.data.data.list.map((item) => {
                      item.hardwareEntity.firstRequestTime = format_time_date(
                        item.hardwareEntity.firstRequestTime
                      );
                      item.hardwareEntity.untilTime = format_time_date(
                        item.hardwareEntity.untilTime
                      );
                    });
                  } else {
                    this.tableData = [];
                  }
                  hideLoading();
                }
                if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                  hideLoading();
                }
                hideLoading();
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                console.log(err, "err1");
                hideLoading();
              },
            };
            request(opt);
          }
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.getBoxEntryLogList,
        params: JSON.stringify({
          hardwareId: this.hardwareId,
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          batchNo: Number(this.activationBatch),
          untilTime: this.untilTime,
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
        }),
        method: "POST",
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code == 0) {
            if (res.data.data) {
              this.tableData = res.data.data.list;
              this.pageLength = res.data.data.total;
              this.pageSize = res.data.data.pageSize;
              this.currentPage = data.pageIndex;
            } else {
              this.tableData = [];
            }
            hideLoading();
          }
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    reset() {
      this.searchType = "";
      this.hardwareId = "";
      this.phone = "";
      this.activationBatch = "";
      this.untilTime = "";
      this.refresh();
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },

  watch: {
    fileTemp(val) {
      if (val) {
        if (
          val.type ==
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          val.type == "application/vnd.ms-excel"
        ) {
          this.importExcelData(val);
        } else {
          this.$message({
            type: "warning",
            message: "附件格式错误，请删除后重新上传！",
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .el-select {
    margin-right: 10px;
  }
  .el-input {
    width: 290px;
    margin-right: 20px;
  }

  ::v-deep .el-dialog {
    border-radius: 15px;
  }

  ::v-deep .el-form-item__content {
    display: flex;
    align-items: center;
    margin-left: 0 !important;
  }

  .el-form {
    .el-button {
      padding: 7px 15px;
      font-size: 12px;
    }
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
}
</style>
<style>
.el-select-dropdown__item.selected {
  border-radius: 10px;
}

.el-dialog {
  border-radius: 15px;
  box-shadow: none;
}
</style>
